import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import { Box, Typography } from '@mui/material';
import Pagination from '../my-profile/Pagination';
import moment from 'moment';

export default function ReferralUsersLists() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [referralUsers, setReferralUsers] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');
  const [filterReferralUsers, setFilterReferralUsers] = React.useState([]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchReferralUsersData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchReferralUsersData(newPage);
  };

  const fetchReferralUsersData = async (pageNumber = 1) => {
    try {
      const response = await http.get(
        `/user/get-referral-users?page=${pageNumber}&limit=${rowsPerPage}`
      );
      const { data, pagination } = response.data;

      setReferralUsers(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log('Error fetching referral users data', error);
    }
  };

  React.useEffect(() => {
    fetchReferralUsersData(page);
  }, [page, rowsPerPage]);

  const filteredReferralUsers = React.useMemo(() => {
    return referralUsers?.filter((curr) => {
      if (!searchValue) return true;
      return curr?.owner_id == searchValue;
    });
  }, [referralUsers, searchValue]);

  React.useEffect(() => {
    setFilterReferralUsers([...filteredReferralUsers]);
  }, [filteredReferralUsers]);

  return (
    <Box
      sx={{
        m: 'auto',
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          mb: 1,
        }}
      >
        <Typography
          sx={{ fontSize: '25px', marginBottom: '30px', fontWeight: 'bold' }}
        >
          Referral Users Lists
        </Typography>
      </Box>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <select
            style={{ padding: '6px', marginBottom: '6px' }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
          <input
            type='search'
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder='Suche...'
            style={{ padding: '8px', marginBottom: '6px' }}
          />
        </div>

        <Table aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                ID
              </TableCell>{' '}
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Owner ID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Referral Code
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Used By User ID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Booking ID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Used At
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Is Redeemed
              </TableCell>
            </TableRow>
          </TableHead>
          {filterReferralUsers?.map((valued, index) => (
            <TableBody>
              <TableRow
                sx={{
                  background: '#F9F9F9 ',
                }}
              >
                <TableCell sx={{}}>{valued?.id} </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.owner_id}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.referral_code}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.used_by_user_id}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.booking_id}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {moment(valued?.used_at).format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {`${valued?.is_redeemed}`}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
        // component={Paper}
      >
        <Pagination
          page={page - 1}
          products={referralUsers}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
    </Box>
  );
}
