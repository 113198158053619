import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';

const Pagination = ({
  page,
  products,
  rowsPerPage,
  totalPages,
  handleFirstPageButtonClick,
  handleBackButtonClick,
  handleNextButtonClick,
  handleLastPageButtonClick,
  handleChangePage,
}) => {
  const [page1, setPage1] = useState(page);

  return (
    <Box sx={{ display: 'flex', gap: 1.3, alignItems: 'center' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        sx={{
          '&:disabled': {
            backgroundColor: '#C0C0C0',
          },
          ':hover': {
            background: '#beafa0',
          },
          background: '#beafa0',
          p: '.5rem',
          height: '2.5rem',
          borderRadius: '.5rem',
        }}
        disabled={page == 0}
        variant='contained'
      >
        <KeyboardDoubleArrowLeft sx={{ color: 'white' }} />
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        sx={{
          '&:disabled': {
            backgroundColor: '#C0C0C0',
          },
          ':hover': {
            background: '#beafa0',
          },
          background: '#beafa0',
          p: '.5rem',
          height: '2.5rem',
          borderRadius: '.5rem',
        }}
        disabled={page == 0}
        variant='contained'
      >
        <KeyboardArrowLeft sx={{ color: 'white' }} />
      </IconButton>

      <Box>
        page{' '}
        <strong>
          {page + 1} of {totalPages}
        </strong>
      </Box>
      <TextField
        type='number'
        value={page1 + 1}
        size='small'
        sx={{ width: '4rem', height: '2.5rem' }}
        onChange={(e) => {
          const newPage = Math.max(
            0,
            Math.min(totalPages - 1, e.target.value - 1)
          );
          setPage1(newPage);
          handleChangePage(e, newPage + 1);
        }}
        inputProps={{ max: totalPages, min: 1 }}
      />
      <IconButton
        onClick={handleNextButtonClick}
        sx={{
          '&:disabled': {
            backgroundColor: '#C0C0C0',
          },
          ':hover': {
            background: '#beafa0',
          },
          background: '#beafa0',
          p: '.5rem',
          height: '2.5rem',
          borderRadius: '.5rem',
        }}
        disabled={page >= totalPages - 1}
        variant='contained'
      >
        <KeyboardArrowRight sx={{ color: 'white' }} />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        sx={{
          '&:disabled': {
            backgroundColor: '#C0C0C0',
          },
          ':hover': {
            background: '#beafa0',
          },
          background: '#beafa0',
          p: '.5rem',
          height: '2.5rem',
          borderRadius: '.5rem',
        }}
        disabled={page >= totalPages - 1}
        variant='contained'
      >
        <KeyboardDoubleArrowRight sx={{ color: 'white' }} />
      </IconButton>
    </Box>
  );
};

export default Pagination;
