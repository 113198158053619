import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Card, CardContent, Button, Fab } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  isSameDay,
  isSunday,
  isToday,
  addMonths,
  isAfter,
  addDays,
} from 'date-fns';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import queryString from 'query-string';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { PACKAGES } from './constants';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function HomeCards({ setSelectDayItem, setActive }) {
  const [packages, setPackages] = useState(PACKAGES);
  const [isNext, setIsNext] = useState(false);

  const selectPackage = (item) => {
    const packagesClone = [...packages];
    const newUpdatedList = packagesClone.map((day) => {
      if (day.id === item?.id) {
        day['isSelected'] = true;
        return day;
      } else {
        day['isSelected'] = false;
        return day;
      }
    });
    setPackages(newUpdatedList);
    setSelectDayItem(item);
    setIsNext(true);
  };

  return (
    <Box style={{ width: '85%', margin: 'auto' }}>
      <br />
      <br />
      <Grid
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
        container
        spacing={2}
      >
        {packages.map((packageItem) => {
          return (
            <Grid item lg={3} xs={12} sm={6} md={3}>
              <Item
                style={{
                  borderRadius: '15px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  background: packageItem?.cardColor,
                }}
              >
                <br />
                <Typography style={{ fontSize: '20px', fontWeight: 600 }}>
                  {packageItem?.title}
                </Typography>
                <Typography style={{ marginTop: '10px' }}>
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    <span
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      {packageItem.id === 5
                        ? `${packageItem?.price},- \u20AC`
                        : `${packageItem?.price},- \u20AC`}
                    </span>
                  </span>
                </Typography>

                <Typography style={{ marginTop: '14px', fontWeight: 'bold' }}>
                  <h4
                    style={{
                      fontWeight: 'bolder',
                      fontSize: '12px',
                      color: 'black',
                    }}
                  >
                    {packageItem?.features}
                  </h4>
                </Typography>
                <br />
                <Typography style={{ marginTop: '15px' }}>
                  <button
                    onClick={() => selectPackage(packageItem)}
                    style={{
                      width: '95%',
                      margin: 'auto',
                      borderRadius: '30px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      background: packageItem?.isSelected
                        ? '#e9c396'
                        : 'lightgrey',
                      fontSize: '15px',
                      color: 'black',
                      border: 'none',
                    }}
                  >
                    {packageItem?.isSelected ? 'Ausgewählt' : 'Jetzt Buchen'}
                  </button>
                </Typography>
              </Item>
            </Grid>
          );
        })}

        <Grid item lg={12} xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '26px',
            }}
          >
            <Button
              disabled
              variant='contained'
              style={{
                marginRight: '8px',
                background: '#7a7e80',
                marginRight: '37px',
                color: '#fff',
              }}
            >
              Zurück
            </Button>

            <Button
              disabled={!isNext}
              variant='contained'
              style={{
                marginRight: '8px',
                background: isNext ? '#b98b8b' : '#7a7e80',
                color: '#fff',
              }}
              onClick={() => {
                setActive(1);
              }}
            >
              Weiter
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
