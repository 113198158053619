import * as React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import http from '../../http';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import Pagination from './Pagination';
import { Spinner } from '../Spinner/Spinner';

export default function BasicTable() {
  const [products, setProducts] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setSearchValue] = React.useState('');

  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [selectedColumn, setSelectedColumn] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');

  const searchableColumns = [
    { label: 'Artikelnummer', value: 'sku' },
    { label: 'Bezeichnung', value: 'title' },
  ];

  const navigate = useNavigate();

  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatPrice = (value) => {
    let price = parseFloat(value).toFixed(2);
    price = price.toLocaleString();
    let priceRaw = price.split('.');
    priceRaw[0] = priceRaw[0].replace(',', '.');
    price = priceRaw[0] + ',' + priceRaw[1];
    return price;
  };

  const filteredProducts = React.useMemo(() => {
    const lowercasedSearchValue = searchValue.toLowerCase();
    return products.filter((product) => {
      if (searchValue) {
        return (
          product.title?.toLowerCase().includes(lowercasedSearchValue) ||
          product.manufacturer?.toLowerCase().includes(lowercasedSearchValue) ||
          product.sku?.toLowerCase().includes(lowercasedSearchValue) ||
          moment(product.createdAt)
            .format('DD-MM-YYYY')
            .includes(searchValue) ||
          product.price.toString().includes(searchValue)
        );
      }
      return true;
    });
  }, [products, searchValue]);

  const sortedProducts = React.useMemo(() => {
    return filteredProducts.sort((a, b) => {
      const aValue = a[orderBy] || '';
      const bValue = b[orderBy] || '';

      if (order === 'asc') {
        return aValue < bValue ? -1 : 1;
      } else {
        return aValue > bValue ? -1 : 1;
      }
    });
  }, [filteredProducts, orderBy, order]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchProductsData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchProductsData(newPage);
  };

  const handleSearch = async () => {
    if (selectedColumn && filterValue) {
      setIsSearchLoading(true);
      try {
        await fetchProductsData(1, {
          column: selectedColumn,
          value: filterValue,
        });
      } catch (error) {
        console.error('Error during search:', error);
      } finally {
        setIsSearchLoading(false);
      }
    } else {
      alert('Please select a column and enter a value to filter.');
    }
  };

  const fetchProductsData = async (pageNumber = 1, filter = {}) => {
    try {
      const id = localStorage.getItem('id');

      let query = `/product/get-all-by-user-id/${id}?page=${pageNumber}&limit=${rowsPerPage}`;

      if (filter.column && filter.value) {
        query += `&filterColumn=${filter.column}&filterValue=${filter.value}`;
      }

      const response = await http.get(query);
      const { data, pagination } = response.data;

      setProducts(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log('Error fetching products data', error);
    }
  };

  React.useEffect(() => {
    fetchProductsData(page);
  }, [page, rowsPerPage]);

  // React.useEffect(() => {
  //   const updateUserData = async () => {
  //     try {
  //       const id = localStorage.getItem('id');
  //       await http.get(`/product/update-all-by-user-id/${id}`);
  //     } catch (error) {
  //       console.log('Error updating user data', error);
  //     }
  //   };

  //   updateUserData();
  // }, []);

  const postToPOS = () => {
    setIsLoading(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/product/add/all/user-products-to-pos',
    };

    http
      .request(config)
      .then((response) => {
        alert(
          'All of your products are added successfully to the POS and ready to sell!'
        );
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
          alert(error.request);
        } else {
          console.log('Error', error.message);
          alert(error.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2rem' }}>
        <Button
          sx={{
            ':hover': {
              bgcolor: '#c7ccc8',
            },
            width: '14rem',
            height: '6rem',
            background: '#c7ccc8',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            p: 0.5,
            borderRadius: '1rem',
          }}
          onClick={() => {
            navigate(`/add_products`);
          }}
        >
          <Typography sx={{ fontSize: '18px' }}>Weitere Artikel </Typography>{' '}
          <Typography sx={{ fontSize: '18px' }}>hinzufügen</Typography>
        </Button>

        <Button
          disabled={isLoading}
          sx={{
            ':hover': {
              bgcolor: '#8b8c84',
            },
            width: '14rem',
            height: '6rem',
            p: 0.5,
            background: '#8b8c84',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: '1rem',
          }}
          onClick={() => postToPOS()}
        >
          <Typography sx={{ fontSize: '16px' }}>Alle Artikel </Typography>{' '}
          <Typography sx={{ fontSize: '16px' }}>hinzugefügt?</Typography>{' '}
          <Typography sx={{ fontSize: '16px' }}>Hier bestätigen!</Typography>
        </Button>

        <Button
          sx={{
            ':hover': {
              bgcolor: '#c7ccc8',
            },
            width: '14rem',
            height: '6rem',
            background: '#c7ccc8',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            p: 0.5,
            borderRadius: '1rem',
          }}
          onClick={() => {
            navigate(`/apply_discount`);
          }}
        >
          <Typography sx={{ fontSize: '18px' }}>
            <span style={{ textDecoration: 'underline' }}>ALLE</span> ARTIKEL
            RABATTIEREN
          </Typography>
        </Button>
      </Box>

      {isLoading ? (
        <Spinner
          text={
            'Artikel werden übermittelt - das kann einen Moment dauern. Den Ladevorgang bitte nicht abbrechen bzw. nicht aktualiseren!'
          }
        />
      ) : (
        <Box sx={{ boxShadow: 1, p: 2, margin: 2 }}>
          <Typography
            sx={{ fontSize: '20px', fontWeight: 'bold', gap: 2, mb: 3 }}
          >
            Übersicht deiner Artikel
          </Typography>

          <Typography
            variant='h6'
            sx={{
              fontSize: '1.25rem',
              fontWeight: 'bold',
              mb: 1,
              color: '#333',
            }}
          >
            Advanced Search
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
            <Select
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
              displayEmpty
              fullWidth
              sx={{
                border: '1px solid #ced4da',
                borderRadius: '4px',
                fontSize: '1rem',
                minWidth: '200px',
              }}
            >
              <MenuItem value='' disabled>
                Select Column
              </MenuItem>
              {searchableColumns.map((col) => (
                <MenuItem key={col.value} value={col.value}>
                  {col.label}
                </MenuItem>
              ))}
            </Select>

            <TextField
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              label='Enter Filter Value'
              fullWidth
              sx={{
                borderRadius: '4px',
                fontSize: '1rem',
                minWidth: '200px',
              }}
            />

            <Button
              variant='contained'
              color='primary'
              onClick={handleSearch}
              disabled={isSearchLoading}
              sx={{
                padding: '10px 16px',
                textTransform: 'none',
                ':hover': {
                  backgroundColor: '#003366',
                },
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isSearchLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Search'
              )}
            </Button>
          </Box>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <select
              style={{ padding: '6px', marginBottom: '6px' }}
              onChange={handleChangeRowsPerPage}
            >
              {[50, 80, 100, 200, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  zeigen {pageSize}
                </option>
              ))}
            </select>
            <input
              type='search'
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder='Suche...'
              style={{ padding: '8px', marginBottom: '6px' }}
            />
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ bgcolor: '#e4f0f1' }}>
                <TableRow>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'sku'}
                      direction={orderBy === 'sku' ? order : 'asc'}
                      onClick={() => handleRequestSort('sku')}
                    >
                      Artikelnummer
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? order : 'asc'}
                      onClick={() => handleRequestSort('title')}
                    >
                      Bezeichnung
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'brand_name'}
                      direction={orderBy === 'brand_name' ? order : 'asc'}
                      onClick={() => handleRequestSort('brand_name')}
                    >
                      Marke
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'quantity'}
                      direction={orderBy === 'quantity' ? order : 'asc'}
                      onClick={() => handleRequestSort('quantity')}
                    >
                      Menge
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'price'}
                      direction={orderBy === 'price' ? order : 'asc'}
                      onClick={() => handleRequestSort('price')}
                    >
                      Preis
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'is_added_to_pos'}
                      direction={orderBy === 'is_added_to_pos' ? order : 'asc'}
                      onClick={() => handleRequestSort('is_added_to_pos')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{
                      fontWeight: '600',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip
                      title='Anzeige aktualisiert sich täglich um 01:00 Uhr'
                      arrow
                    >
                      <InfoIcon
                        sx={{
                          marginRight: 1,
                          fontSize: '20px',
                          cursor: 'pointer',
                        }}
                      />
                    </Tooltip>
                    <TableSortLabel
                      active={orderBy === 'inventory_change_timestamps'}
                      direction={
                        orderBy === 'inventory_change_timestamps'
                          ? order
                          : 'asc'
                      }
                      onClick={() =>
                        handleRequestSort('inventory_change_timestamps')
                      }
                    >
                      Verkaufsdatum
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'createdAt'}
                      direction={orderBy === 'createdAt' ? order : 'asc'}
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Hinzugefügt am
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{ fontWeight: '600' }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts.map((valued, index) => (
                  <TableRow key={index} sx={{ background: '#F9F9F9 ' }}>
                    <TableCell>{valued.sku}</TableCell>
                    <TableCell align='left'>{valued.title}</TableCell>
                    <TableCell align='left'>{valued.brand_name}</TableCell>
                    <TableCell align='left'>{valued.quantity}</TableCell>
                    <TableCell align='left'>
                      {formatPrice(valued.price)}
                    </TableCell>
                    <TableCell align='left'>
                      {valued?.is_added_to_pos == 1
                        ? 'Aktiv'
                        : valued?.is_added_to_pos == 2
                        ? 'Verkauft'
                        : 'Neu'}
                    </TableCell>
                    <TableCell align='left' sx={{ whiteSpace: 'pre-wrap' }}>
                      {valued?.inventory_change_timestamps
                        ?.split(',')
                        .map((timestamp, index) => {
                          const formattedDate = moment(
                            new Date(timestamp)
                          ).format('DD-MM-YYYY');
                          return (
                            <div key={index}>
                              {index + 1}. {formattedDate}
                            </div>
                          );
                        })}
                    </TableCell>
                    <TableCell align='left'>
                      {moment(valued.createdAt).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell align='left'>
                      {valued.is_added_to_pos === 0 && (
                        <Button
                          onClick={() => {
                            navigate(`/update_products/${valued?.id}`);
                          }}
                          style={{
                            borderRadius: '20px',
                            backgroundColor: '#d0a667',
                            color: 'white',
                          }}
                        >
                          Bearbeiten
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ p: 2, display: 'flex', justifyContent: 'end' }}>
            <Pagination
              page={page - 1}
              products={products}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              handleFirstPageButtonClick={() => setPage(1)}
              handleBackButtonClick={() => setPage(page - 1)}
              handleNextButtonClick={() => setPage(page + 1)}
              handleLastPageButtonClick={() => setPage(totalPages)}
              handleChangePage={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
