import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Calendar from 'react-calendar';
import moment from 'moment';
import http from '../../http';
import {
  isSameDay,
  isSunday,
  isToday,
  addMonths,
  isAfter,
  parseISO,
} from 'date-fns';
import { Box, Grid, Paper, Button, Typography } from '@mui/material';
import './APIrecordDesign/style2.css';
import 'react-calendar/dist/Calendar.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import './APIrecordDesign/style2.css';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function DateRangeCalendarValue({
  selectDayItem,
  setSelectedEndDate,
  setSelectedStartDate,
  selectedStartDate,
  selectedEndDate,
  selectedStand,
  setSelectedStand,
  setActive,
}) {
  const navigate = useNavigate();
  const [disabledDates, setDisabledDates] = useState([]);
  // const [selectedStartDate, setSelectedStartDate] = useState(null);
  // const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [isRange, setRange] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [shelfList, setShelfList] = useState([]);

  useEffect(() => {
    setLoading(true);
    http
      .request({
        method: 'get',
        maxBodyLength: Infinity,
        url: '/holidays',
        headers: {},
      })
      .then((response) => {
        const { data } = response.data;
        setDisabledDates(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    startDate.setHours(0, 0, 0, 0);
    const endDate = calcWorkingDays(
      new Date(startDate),
      selectDayItem?.duration
    );

    setDate([new Date(startDate), new Date(endDate)]);
    setSelectedStartDate(new Date(startDate));
    setSelectedEndDate(endDate);

    loadShelfData(new Date(startDate), endDate);
  }, [selectDayItem?.duration]);

  const tileDisabled = ({ date, view }) => {
    const tempDate = addMonths(new Date(), 7);

    // Disable tiles in month view only
    if (isSunday(new Date(date))) {
      return true;
    } else if (isToday(new Date(date))) {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      return true;
    } else if (isAfter(date, new Date(tempDate))) {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      return true;
    } else {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      return disabledDates.find((dDate) =>
        isSameDay(new Date(dDate?.holiday_date), date)
      );
    }
  };

  const calcWorkingDays = (fromDate, days) => {
    var count = 1;
    while (count < days) {
      fromDate.setDate(fromDate.getDate() + 1);
      if (
        fromDate.getDay() != 0 &&
        !disabledDates.find((dDate) =>
          isSameDay(parseISO(dDate.holiday_date), fromDate)
        )
      )
        // Skip weekends
        count++;
    }
    return fromDate;
  };

  const onDaySelect = (value) => {
    const workingDays = calcWorkingDays(
      new Date(value),
      selectDayItem?.duration
    );

    setDate([new Date(value), new Date(workingDays)]);
    // setRange(isRange);
    setSelectedStartDate(new Date(value));
    setSelectedEndDate(workingDays);
    loadShelfData(new Date(value), workingDays);
  };

  const loadShelfData = (startDate, endDate) => {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const startUTC = new Date(
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      )
    );
    startUTC.setUTCHours(0, 0, 0, 0);

    const endUTC = new Date(
      Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    );
    endUTC.setUTCHours(23, 59, 0, 0);

    setLoading(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/stand/stand_status_by_dates?startDate=${startUTC.toISOString()}&endDate=${endUTC.toISOString()}`,
      headers: {},
    };

    http
      .request(config)
      .then((response) => {
        const { data } = response.data;
        setShelfList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const StandBox = ({
    width,
    height,
    x,
    y,
    fill,
    label,
    id,
    isAvailable,
    status,
  }) => {
    let shapeElement;
    let styleProps = {};

    shapeElement = (
      <rect
        id={`shelf_${id}`}
        data-shelf_id={`${id}`}
        data-shelf_label={`${label}`}
      />
    );

    if (fill) {
      styleProps.fill = fill;
    }
    if (width) {
      styleProps.width = width;
    }
    if (height) {
      styleProps.height = height;
    }
    if (x) {
      styleProps.x = x;
    }
    if (y) {
      styleProps.y = y;
    }

    return (
      <g
        transform='matrix(1,0,0,1,0,0)'
        className={`${
          status == 'active' ? 'cursor-pointer' : 'cursor-no-allowed '
        } animate`}
        role='button'
        onClick={() => {
          if (isAvailable) {
            setSelectedStand({ id, label, x, y, isAvailable });
            setIsNext(true);
          } else {
            return null;
          }
        }}
      >
        {React.cloneElement(shapeElement, {
          style: {
            ...styleProps,
            fill: selectedStand?.id == id ? '#D2A98B' : fill,
          },
        })}
        {status == 'active' ? (
          <text
            x={`${Number(x) + width / 2}`}
            y={`${Number(y) + 3}`}
            fill='#000'
            fontFamily='Helvetica'
            fontSize='14'
            textAnchor='middle'
            className=''
          >
            <tspan dy='18.2' x={`${Number(x) + width / 2}`}>
              {label}
            </tspan>
          </text>
        ) : (
          <text
            x={`${Number(x) + width / 2}`}
            y={`${Number(y) + 3}`}
            fill='#000'
            fontFamily='Helvetica'
            fontSize='14'
            textAnchor='middle'
            className=''
          >
            <tspan dy='18.2' x={`${Number(x) + width / 2}`}>
              {label}
            </tspan>
          </text>
        )}
        {label != 'Kasse' &&
        label != 'Eingang' &&
        label != 'WC / Still- und Wickelraum' ? (
          <>
            <line
              x1={x}
              y1={y}
              x2={Number(x) + width}
              y2={y}
              strokeWidth='1'
              stroke='#000000'
            ></line>
            <line
              x1={x}
              y1={Number(y) + width}
              x2={Number(x) + width}
              y2={Number(y) + height}
              strokeWidth='1'
              stroke='#000000'
            ></line>
            <line
              x1={x}
              y1={y}
              x2={x}
              y2={Number(y) + height}
              strokeWidth='1'
              stroke='#000000'
            ></line>
            <line
              x1={Number(x) + width}
              y1={y}
              x2={Number(x) + width}
              y2={Number(y) + height}
              strokeWidth='1'
              stroke='#000000'
            ></line>
          </>
        ) : null}
      </g>
    );
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        style={{ zIndex: 99999 }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid
        container
        spacing={2}
        sx={{ boxShadow: 0 }}
        justifyContent={'center'}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{ boxShadow: 0 }}
          justifyContent={'center'}
        >
          <Typography
            sx={{
              fontSize: { xs: 18 },
              textAlign: 'center',
              marginBottom: '30px',
            }}
          >
            Wähle deinen gewünschten Zeitraum
          </Typography>

          <Item sx={{ boxShadow: 0 }}>
            <div className='calendar-container d-flex justify-content-center'>
              <Calendar
                className='custom-calendar-container'
                onChange={onDaySelect}
                value={date}
                selectRange={isRange}
                minDetail='year'
                // showDoubleView={true}
                minDate={new Date()}
                allowPartialRange={true}
                tileDisabled={tileDisabled}
                // activeStartDate={new Date()} // pass in state var in activeStartDate
                // showNeighboringMonth={false}
                // showFixedNumberOfWeeks={true}
                goToRangeStartOnSelect={true}
              />
            </div>
            {selectedStartDate ? (
              <Box
                sx={{
                  bgcolor: 'white',
                  p: '1rem',
                  pl: '2rem',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                {' '}
                {moment(new Date(selectedStartDate)).format(
                  'L'
                )}&nbsp;|&nbsp; {moment(new Date(selectedEndDate)).format('L')}
              </Box>
            ) : null}
          </Item>
        </Grid>
      </Grid>

      {shelfList.length > 0 && (
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '30px' }}
          justifyContent={'center'}
        >
          <Grid item xs={12} md={10}>
            <svg
              width='980'
              height='600'
              style={{ marginTop: -100 }}
              viewBox='0 0 980 600'
            >
              {shelfList.map((item, index) => (
                <StandBox
                  key={index}
                  fill={
                    item?.status == 'active'
                      ? item?.is_available == 1
                        ? '#d9d9d9'
                        : '#ff8080'
                      : item?.label == 'Kasse' ||
                        item?.label == 'Eingang' ||
                        item?.label == 'WC / Still- und Wickelraum'
                      ? '#dabcbc'
                      : '#ff8080'
                  }
                  width={item?.width}
                  height={item?.height}
                  x={item?.x}
                  y={item?.y}
                  id={item?.id}
                  label={item?.label}
                  status={item?.status}
                  isAvailable={
                    item?.is_available == 1 && item?.status == 'active'
                      ? true
                      : false
                  }
                />
              ))}
            </svg>
          </Grid>
        </Grid>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Button
          variant='contained'
          style={{
            marginRight: '8px',
            background: '#b98b8b',
            marginRight: '37px',
          }}
          onClick={() => {
            setActive(0);
          }}
        >
          Zurück
        </Button>
        <Button
          disabled={!isNext || !selectedStand}
          variant='contained'
          style={{
            marginRight: '8px',
            background: isNext ? '#b98b8b' : '#7a7e80',
            color: '#fff',
          }}
          onClick={() => {
            setActive(3);
          }}
        >
          Weiter
        </Button>
      </Box>
    </Box>
  );
}
